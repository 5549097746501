hr.divider-brand {
	border-top: 1px solid $brandcolor2;
	color: $brandcolor2;
}

hr.divider-gray {
	border-top: 1px solid $gray-light3;
	color: $gray-light3;
}

hr.divider-short {
	width: 6.25em;
}

hr.divider-left {
	margin: 0.625em 0 1.25em 0;
	@media (max-width: 576px) {
		margin: 0.625em auto 1.25em auto;
	}
}
