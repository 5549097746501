header {
	.header-top-container {
		background-color: $white;
		padding: 0.9375em 2.5em;
		.row {
			display: flex;
			justify-content: center;
			align-items: center;
			@media (max-width: 576px) {
				justify-content: center;
			}
		}
		.header-logo {
			display: inline-block;
			width: 357px;
			height: 68px;
			background: url("/assets/images/Branding/heidman-law-logo-horizontal.svg") center center/contain no-repeat;
			text-indent: -9999px;
			margin: 0.625em 0.625em;
			@media (max-width: 992px) {
				width: 268px;
				height: 51px;
			}
			@media (max-width: 768px) {
				width: 180px;
				height: 57px;
			}
			@media (max-width: 576px) {
				width: 75vw;
				height: 68px;
			}
		}
		.header-info-container {
			display: inline-block;
			width: auto;
			.row {
				.col-6 {
					width: auto;
				}
			}
			b {
				font-family: adobe-garamond-pro, Didot, Georgia, 'Times New Roman', 'American Typewriter', serif;
				font-size: 1.125em;
				text-transform: uppercase;
				color: $brandcolor1-dark;
				white-space: nowrap;
			}
			a {
				color: $gray-dark1;
				&:hover {
					color: $brandcolor1;
					text-decoration: none;
				}
				&:active, &:focus, &:not(:disabled):not(.disabled):focus {
					color: $brandcolor1-dark;
					text-decoration: none;
				}
			}
		}
	}
	.header-nav-container {
		background-color: $brandcolor1-dark;
		padding: 0;
		.navbar {
			padding: 0;
		}
	}
}
