html {
	box-sizing: border-box;
	background-color: $white;
	font-size: calc(0.626em + 0.47vw);
	font-size: -webkit-calc(0.66em + 0.47vw);
	font-size: -moz-calc(0.66em + 0.47vw);
	@media (max-width: 576px) {
		font-size: 0.875em;
	}
	@media (min-width: 1200px) {
		font-size: 1.0em;
	}
}

*, 
*::before, 
*::after { box-sizing: inherit; }

html {
	background-color: $brandcolor1-dark;
}

body {
	position: relative;
	background-color: $white;
	margin: 0 !important;
	overflow-x: hidden;
}

.hide-vis {
	display: none;
}

.jumbotron-fluid {
	position: relative;
	z-index: 1;
	margin: 0 !important;
	overflow-x: hidden;
}

.container-max {
	max-width: 1800px;
}

.col-md-border:not(:last-child) {
	@media (min-width: 992px) {
		border-right: 1px solid $gray-light1;
	}
	@media (max-width: 992px) {
		border-bottom: 1px solid $gray-light1;
	}
}

.col-md-border + .col-md-border {
	@media (min-width: 992px) {
		margin-left: -1px;
	}
	@media (max-width: 992px) {
		margin-top: -1px;
	}
}

thead.thead-branded {
	tr th {
		color: $gray-mid3;
		font-weight: 400;
		font-size: 1.375em;
		border-top: none;
		border-bottom: 2px solid $brandcolor1;
		@media (max-width: 992px) {
			font-size: 1.25em;
		}
		@media (max-width: 768px) {
			font-size: 1.125em;
		}
	}
}

tbody.tbody-branded {
	tr td {
		line-height: 1;
		border-top: 1px dotted $gray-light3;
		@media (max-width: 992px) {
			font-size: 0.875em;
		}
		@media (max-width: 768px) {
			font-size: 0.75em;
		}
	}
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: $brandcolor1-light;
}
