/* Pagination Classes */

.pagination {
	font-size: 0.875em;
	font-weight: 700;
	background: none;
	border-radius: 0;
	border: 1px solid $brandcolor1;
	a {
		color: $gray-dark2;
		background: none;
		padding: 0.625em 1.25em;
		border: none;
		&:hover {
			color: $brandcolor1;
			background: none;
		}
	}
	.active {
		a {
			padding: 0.625em 1.25em;
			color: $white;
			background-color: $brandcolor1 !important;
			pointer-events: none;
			cursor: auto;
		}
	}
	.page-item:not(:last-child) {
		margin: 0;
		border-right: 1px solid $gray-light3;
	}
	.page-item + .page-item {
		margin: 0;
		margin-left: -1px;
	}

	.page-item.disabled { 
		.page-link {
			color: $gray-light3 !important;
			background: none;
			pointer-events: none;
			cursor: auto;
		}
	}
}