/* Practices Cards */
.card-practice-container {
	margin-top: 2.0em;
	padding: 0 1.25em 5.0em 1.25em;
	@media (max-width: 768px) {
		padding: 0em 1.0em 5.0em 1.0em;
	}
}

.practice-card-large, .practice-card-small {
	margin: 0 1.25em 2.5em 1.25em;
	border-radius: 0;
	width: 100%;
	background-color: $brandcolor1-dark;
	&:hover {
		background-color: $white;
		.card-img {
			img.card-img-back {
				transform: scale(1.1);
			}
			.card-back-overlay {
				background-color: rgba($white,0.5);
				backdrop-filter: blur(6px);
			}
		}
		.card-img-overlay {
			h2, h3 {
				color: $brandcolor1-dark;
			}
		}
	}
	.card-img {
		top: initial;
		border-radius: 0;
		overflow: hidden;
		img.card-img-back {
			-webkit-transition: all 4.0s ease-out;
			-moz-transition: all 4.0s ease-out;
			-ms-transition: all 4.0s ease-out;
			-o-transition: all 4.0s ease-out;
			transition: all 4.0s ease-out;
		}
		.card-back-overlay {
			position: absolute;
			left: 0;
			bottom: 0;
			width: 100%;
			height: 100%;
			background-color: rgba($gray-dark2,0.25);
			z-index: 0;
			-webkit-transition: all 2.0s ease-out;
			-moz-transition: all 2.0s ease-out;
			-ms-transition: all 2.0s ease-out;
			-o-transition: all 2.0s ease-out;
			transition: all 2.0s ease-out;
			backdrop-filter: blur(0px);
		}
	}
	.card-img-overlay {
		border-radius: 0;
		h2, h3 {
			color: $white;
			-webkit-transition: color 1.0s ease-out;
			-moz-transition: color 1.0s ease-out;
			-ms-transition: color 1.0s ease-out;
			-o-transition: color 1.0s ease-out;
			transition: color 1.0s ease-out;
		}
	}
}


/* Practice Details Pages */
.body-practices {
	.intro-container {
		ul {
			list-style-type: none;
			column-count: 2;
			column-gap: 2.5em;
			column-rule: 1px dotted $gray-white2;
			margin: 0 0 2.5em 0;
			padding: 0;
			@include media-breakpoint-only(lg) {
				column-count: 1;
			}
			@include media-breakpoint-only(xs) {
				column-count: 1;
			}
			li {
				margin: 0;
				padding: 0.625em 0;
				line-height: 1;
				border-bottom: 1px solid $gray-light1;
				&:first-child { 
					// padding-top: 0; 
				}
				&:last-child { 
					border-bottom: none; 
				}
				&::marker {
					content: "";
					display: none;
				}
				b {
					color: $black;
					font-weight: 600;
				}
				&.active {
					a {
						color: $gray-dark1;
					}
				}
			}
		}
		hr {
			border-top: 1px solid $brandcolor2;
			color: $brandcolor2;
		}
	}
}

.attorneys-list-container {
	padding: 2.0em 2.5em 2.0em 1.0em !important;
	.attorney-list-title {
		padding-bottom: 0.25em;
		border-bottom: 1px solid $brandcolor2;
	}
	ul {
		li {
			font-family: proxima-nova-condensed, Roboto, Helvetica, Calibri, 'Segoe UI', sans-serif;
			font-size: 1.125em;
			a {
				font-weight: 600;
				&:hover {
					text-decoration: none;
				}
			}
		}
	}
}

.accordion-practice-desc {
	padding: 0 1.25em 5.0em 1.25em;
	.card {
		margin: 0;
		.card-header {
			margin: 0;
			padding: 0.9375em 0.5625em;
			border-bottom: 1px solid $gray-light2;
			.btn {
				margin: 0;
				padding: 0;
				font-family: adobe-garamond-pro, Didot, Georgia, 'Times New Roman', 'American Typewriter', serif;
				font-weight: 400;
				font-size: 2em;
				line-height: 1;
				color: $brandcolor1;
				text-transform: none;
				letter-spacing: 0;
				.active.focus, .active:focus, .focus, .focus:active, &:active:focus, &:focus {
					outline: 0 !important;
					outline-offset: 0 !important;
					background-image: none !important;
					-webkit-box-shadow: none !important;
					-moz-box-shadow: none !important;
					box-shadow: none !important;
				}
				&[aria-expanded="true"] {
					.fac { transform: rotate(180deg); }
				}
				.fac {
					display: inline-block;
					position: absolute;
					top: 0.5625em;
					right: 0.5625em;
					transition: transform 300ms ease-in-out;
					transform: rotate(0deg);
					color: $brandcolor2;
				}
			}
		}
		.card-body {
			font-size: 1.0rem;
			padding: 1.25em 0.9375em 1.25em 0.9375em;
			p { font-size: 1.0rem; }
			h3 { 
				font-size: 1.875rem;
				color: $gray-dark1;
			}
		}
	}
}

.practice-related-news-container {
	padding: 0 2.25em;
}

