footer {
	@media (max-width: 576px) {
		font-size: 1.0625em;
	}
}

//Footer Top Rows
.footer-top {
	.footer-top-inner {
		padding: 3.75em 2.5em 0 2.5em;
		text-align: center;
		.row {
			display: flex;
			justify-content: center;
			align-items: center;
			.col-12 {
			}
		}
		.footer-logo-container { 
			padding: 0.625em 45px 0.625em 0;
			// @media (max-width: 992px) {
			// 	padding-right: 28px;
			// }
			// @media (max-width: 768px) {
			// 	padding-right: 45px;
			// }
			.footer-logo {
				display: block;
				width: 300px;
				height: 57px;
				background: url("/assets/images/Branding/heidman-law-logo-horizontal.svg") center center/contain no-repeat;
				text-indent: -9999px;
				margin: 0.625em;
				// @media (max-width: 992px) {
				// 	width: 225px;
				// 	height: 43px;
				// }
				// @media (max-width: 768px) {
				// 	width: 300px;
				// 	height: 57px;
				// }
			}
		}
		.footer-body {
			font-family: adobe-garamond-pro, Didot, Georgia, 'Times New Roman', 'American Typewriter', serif;
			font-weight: 600;
			font-size: 1.0em;
			line-height: 1;
			letter-spacing: 0.5em;
			text-transform: uppercase;
			color: $brandcolor1;
			margin: 0;
			padding: 2.0em 0;
			@media (max-width: 768px) {
				padding: 0 0 1.25em 0;
			}
		}
		.footer-button {
			margin: 0;
			padding: 1.5em 0;
			@media (max-width: 768px) {
				padding: 1.5em 0 3.0em 0;
			}
			.btn {
				font-family: proxima-nova-condensed, Roboto, Helvetica, Calibri, 'Segoe UI', sans-serif;
				font-size: 0.875em;
				line-height: 1;
				font-weight: 600;
				letter-spacing: 0.125em;
				margin: 0 !important;
				padding: 0.625em 2.5em;
			}
		}
	}
}

//Footer Middle Rows
.footer-middle {
	position: relative;
	background-color: $gray-white1;
	background-repeat: none;
	background-size: cover;
	.background-overlay {
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 0;
		background-color: $gray-white1;
		opacity: .9;
	}
	.footer-middle-inner {
		padding: 3.125em 0em 2.5em 0em;
		.footer-links, .footer-address {
			padding: 0.9375em 1.25em;
			h5 {
				display: block;
				width: 100%;
				padding-bottom: 0.3125em;
				border-bottom: 1px solid $brandcolor2;

			}
		}
		.footer-links {
			ul.unstyled {
				list-style-type: none;
				margin: 0;
				padding: 0;
				li {
					font-family: proxima-nova-condensed, Roboto, Helvetica, Calibri, 'Segoe UI', sans-serif;
					font-weight: 600;
					font-size: 1.125em;
					line-height: 1;
					a {
						padding-left: 0;
						color: $brandcolor1;
						&:hover {
							color: $brandcolor1-light;
							text-decoration: none;
						}
						&:active, &:focus, &:not(:disabled):not(.disabled):focus {
							color: $brandcolor1-dark;
							text-decoration: none;
						}
					}
				}
			}
		}
		.footer-address {
			p {
				font-size: 0.9375em;
				line-height: 1.25;
				color: $gray-dark1;
			}
			a {
				color: $gray-dark1;
				&:hover {
					color: $brandcolor1;
					text-decoration: none;
				}
				&:active, &:focus, &:not(:disabled):not(.disabled):focus {
					color: $brandcolor1-dark;
					text-decoration: none;
				}
			}
		}
	}
}

// Footer Bottom Rows
.footer-bottom {
	background-color: $brandcolor1-dark;
	.footer-bottom-inner {
		padding: 2.1875em 1.25em;
		text-align: center;
		.row-top {
			padding: 0em;
			border-bottom: 1px solid $brandcolor2;
		}
		.row-bottom {
			padding: 1.25em 0;
		}
		.footer-body {
			color: $white;
			font-family: proxima-nova-condensed, Roboto, Helvetica, Calibri, 'Segoe UI', sans-serif;
			font-size: 1em;
		}
		.footer-copyright {
			color: $brandcolor1-light;
			font-size: 0.875em;
			line-height: 1;
		}
		.footer-links {
			li.list-inline-item a {
				font-family: proxima-nova-condensed, Roboto, Helvetica, Calibri, 'Segoe UI', sans-serif;
				font-size: 1em;
				line-height: 1;
				font-weight: 600;
				padding: 0 0.625em 0 0.625em !important;
				color: $white;
				&:hover {
					color: $brandcolor2;
					text-decoration: none;
				}
				&:active, &:focus, &:not(:disabled):not(.disabled):focus {
					color: $brandcolor2-dark;
					text-decoration: none;
				}
			}
		}
		.footer-social-icon {
			color: $white;
			line-height: 1;
			&:hover {
				color: $brandcolor2;
				text-decoration: none;
			}
			&:active, &:focus, &:not(:disabled):not(.disabled):focus {
				color: $brandcolor2-dark;
				text-decoration: none;
			}
		}
		.footer-social-desc {
			display: none;
		}
	}
}
