header {
	#header-menu {
		position: relative;
		background-color: $brandcolor1-dark;
		padding: 1.0em;
		li {
			display: block;
			transition-duration: 0.5s;
			&:hover {
				cursor: default;
				&.pointer {
					cursor: pointer;
				}
			}
		}

		ul li ul {
			visibility: hidden;
			opacity: 0;
			position: absolute;
			left: 0.5em;
			transition: all 0.5s ease-out;
			display: none;
			padding-top: 0.625em !important;
			a {
				color: $white;
				background-color: $brandcolor1-dark;
				padding: 0.625em 0 !important;
				margin: 0 1.875em !important;
				white-space: nowrap;
				&:hover {
					color: $gray-light1;
				}
			}
		}

		ul li:hover > ul,
		ul li ul:hover {
			visibility: visible;
			opacity: 1;
			display: block;
		}

		ul li ul li {
			clear: both;
			width: 100%;
		}
	}
	nav {
		.navbar-nav {
			margin-left: auto;
			margin-right: auto;
		}
		.nav-link, .dropdown-item {
			font-family: proxima-nova-condensed, Roboto, Helvetica, Calibri, 'Segoe UI', sans-serif;
			font-size: 1.0em;
			line-height: 1;
			font-weight: 700;
			letter-spacing: .25em;
			text-transform: uppercase;
			color: $white;
			margin: 0 1.875em 0 1.875em !important;
			@media (max-width: 1390px) {
				font-size: 0.875em !important;
				margin: 0 1.25em 0 1.25em !important;
			}
			@media (max-width: 1210px) {
				font-size: 0.75em !important;
				margin: 0 0.9375em 0 0.9375em !important;
			}
			@media (max-width: 1070px) {
				font-size: 0.75em !important;
				margin: 0 0.5em 0 0.5em !important;
			}
			.nav-link-arrow {
				padding: 0 0 0 0.625em;
				color: $brandcolor2;
			}
			&.active  {
				background: none !important;
				border-bottom: 1px solid $brandcolor2;
			}
		}
		.dropdown-item {
			margin: 0.625em 0;
			padding: 0.625em 0;
			color: $white;
			width: auto;
			border: none !important;
			&:hover {
				color: $gray-light1;
				background: none;
			}
			&:active, &:focus, &:not(:disabled):not(.disabled):focus {
				color: $gray-light1 !important;
			}
		}
		.dropdown-menu {
			background-color: $brandcolor1-dark;
			border: none;
		}
		.nav-item {
			margin: 0;
			position: relative;
			.nav-link {
				color: $white;
			}
			&:hover {
				.nav-link {
					color: $gray-light1;
				}
			}
			&:active, &:focus, &:not(:disabled):not(.disabled):focus {
				.nav-link {
					color: $gray-light1 !important;
				}
			}
		}
		.dropdown-menu {
			padding: 0 !important;
			margin: 0 !important;
			.nav-link {
				margin-left: 0 !important;
				margin-right: 0 !important;
			}
		}
	}
	.navbar-toggler {
		padding: 0.875em 0.875em;
		font-family: proxima-nova-condensed, Roboto, Helvetica, Calibri, 'Segoe UI', sans-serif;
		font-size: 0.875em;
		line-height: 1;
		font-weight: 700;
		letter-spacing: .25em;
		text-transform: uppercase;
		color: $white;
		align-items: center !important;
		text-align: center !important;
	}

	.navbar-toggler-icon {
		background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255,1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
	}
}

@media (max-width: 991.9px) {
	#header-menu {
		position: absolute;
		top: 0;
		right: 0;
		width: 100vw;
		//height: calc(100vh - 12.5em);
		margin: 0;
		padding: 0;
		color: $white;
		> .navbar-nav {
			display: inline-block;
    	float: none;
			text-align: center;
			margin: 0 !important;
			padding: 0 !important;
			max-height: 100%;
			overflow-y: scroll;
			border: none;
			li {
				a {
					background: none;
					padding: 0.625em 1.25em;
					font-size: 1.125em !important;
					color: $white;
					border: none;
					text-align: center !important;
					&:hover {
						color: $gray-light1;
					}
				}
			}
			ul {
				display: block;
				position: relative;
				opacity: 1;
				visibility: visible;
				margin: 0;
				padding: 0;
				.nav-item {
					display: block;
				}
			}
		}
		.navbar .navbar-collapse {
			text-align: center;
		}
		ul li ul {
			visibility: visible;
			opacity: 1;
			position: relative;
			display: block;
			border: none;
		}
	}
}

.link-text-sm {
	font-family: proxima-nova-condensed, Roboto, Helvetica, Calibri, 'Segoe UI', sans-serif;
	font-weight: 600;
	.fa {
		color: $brandcolor2;
		transform: translateY(0.0625em);
	}
}

.nav-tabs {
	margin: 2.5em 0 0 0 !important;
	padding: 0 !important;
	width: 100%;
	background-color: $white !important;
	border-top: 1px solid $brandcolor2;
	border-bottom: 1px solid $white;
	li {
		//border-bottom: 1px solid $brandcolor2;
		margin: 0 !important;
		padding: 0 !important;
		&::marker {
			content: "" !important;
			display: none !important;
		}
		.nav-link {
			color: $brandcolor1;
			margin: 0 !important;
			padding: 0.625em 0.625em !important;
			&:hover {
				color: $brandcolor1-dark;
				border-radius: 0;
				border: 1px solid $white !important;
				margin: 0 !important;
				padding: 0.625em !important;
			}
		}
		.active {
			background: linear-gradient(180deg, rgba($gray-white2,1) 0%, rgba($gray-white2,0) 100%);
			border-radius: 0 !important;
			border-left: 1px solid $brandcolor2 !important;
			border-right: 1px solid $brandcolor2 !important;
			border-bottom: 1px solid $white !important;
			border-top: 1px solid $gray-light1 !important;
			&:hover {
				background-color: $white !important;
				border-radius: 0 !important;
				border-left: 1px solid $brandcolor2 !important;
				border-right: 1px solid $brandcolor2 !important;
				border-bottom: 1px solid $white !important;
				border-top: 1px solid $white !important;
			}
		}
	}
}
