.banner-top {
	position: absolute;
	top: 0;
	right: 0;
	background: url("/assets/images/backgrounds/top-stripe-white.png") center center/cover no-repeat;
	z-index: 200;
	width: 100%;
	height: 1.25em;
}

.carousel-item {
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
	-webkit-transform-style: preserve-3d;
	-moz-transform-style: preserve-3d;
	transform-style: preserve-3d;
}

.carousel-overlay {
	opacity: 0.5;
	background: linear-gradient(180deg, rgba($white,0.8) 0%, rgba($white,0.25) 33%, rgba($white,0.25) 66%, rgba($white,0.8) 100%);
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
}
.carousel-overlay-2 {
	opacity: 0.5;
	background: radial-gradient(circle, rgba($white,0.2) 0%, rgba($white,0.6) 100%);
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
}

.carousel-caption {
	position: absolute;
	left: initial;
	right: initial;
	bottom: 40%;
	transform: translateY(40%);
	width: 100%;
	display: flex;
	justify-content: space-around;
	@media (max-width: 576px) {
		font-size: 0.875em;
	}
	.caption-container {
		display: flex-column;
		width: auto;
		margin: 2.0em;
	}
	.caption-overlay-image {
		width: 9.875em;
		height: auto;
		padding: 0 0 1.0em 0;
	}
	h1 {
		margin: 0;
		padding: 0;
		font-size: 4.0em;
		color: $brandcolor1-dark;
		sup {
			small {
				font-size: 0.5em;
			}
		}
	}
	h3 {
		margin: 0;
		font-size: 1.75em;
		font-weight: 600;
		color: $brandcolor1-dark;
		sup {
			small {
				font-size: 0.5em;
			}
		}
	}
	.caption-headline-block {
		position: relative;
		padding-bottom: 0;
	}
	hr.caption-divider {
		width: auto;
		overflow: visible; /* For IE */
		padding: 0;
		margin: -0.3125em 0 0.625em 0;
		border: none;
		border-top: 1px solid $brandcolor2;
		color: $brandcolor2;
	}
	.caption-body {
		margin: 0;
		padding: 1.5em 0 0 0;
		p {
			font-family: adobe-garamond-pro, Didot, Georgia, 'Times New Roman', 'American Typewriter', serif;
			font-weight: 600;
			font-size: 1.125em;
			line-height: 1;
			letter-spacing: 0.5em;
			text-transform: uppercase;
			color: $brandcolor1-dark !important;
		}
	}
}

.carousel-control-prev, .carousel-control-next {
	width: auto;
	padding: 1.25em;
	background-image: none;
  border-radius: 50%;
}

.carousel-control-prev-icon, .carousel-control-next-icon {
	background-image: none;
}

.carousel-control-prev-icon::after {
	font-style: normal;
	font-variant: normal;
	text-rendering: auto;
	-webkit-font-smoothing: antialiased;
	font-family: "Font Awesome 5 Free"; font-weight: 900; content: "\f053";
	color: $white;
	font-size: 2.625em;
}

.carousel-control-next-icon::after {
	font-style: normal;
	font-variant: normal;
	text-rendering: auto;
	-webkit-font-smoothing: antialiased;
	font-family: "Font Awesome 5 Free"; font-weight: 900; content: "\f054";
	color: $white;
	font-size: 2.625em;
}