//Add img-fluid responsiveness to all img tags within the body section.
.body {
	img {
		max-width: 100%;
		height: auto;
	}
}

.img-zoom-hover {
	position: relative;
	overflow: hidden;
	& > * {
		z-index: 2;
	}
	.img-zoom-hover-photo {
		display: inline-block;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
		transition: transform 2.0s ease-out;
	}
}
