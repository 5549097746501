.entry-pages-home {

	#our-firm {
		z-index: 0;
		overflow: visible;
		.background-image-overlay {
			width: 100vw;
			height: 150%;
		}
		.slider-wrapper {
			text-align: left;
			h2 {
				font-size: 3.0em;
				color: $gray-mid2;
				b {
					font-weight: 400;
					color: $brandcolor1-dark;
				}
			}
			.slider {
				position: relative;
				display: block;
				.hidden-text {
					visibility: hidden;
				}
				.slider-text1, 
				.slider-text2, 
				.slider-text3 {
					display: block;
					position: absolute;
					left: 0;
					top: 50%;
					transform: translateY(-50%);
					width: 100%;
					text-align: center;
					animation-duration: 20s;
					animation-timing-function: ease-in-out;
					animation-iteration-count: infinite;
				}
				.slider-text1 {
					animation-name: slider-anim-1;
					z-index: 1;
				}

				.slider-text2 {
					animation-name: slider-anim-2;
					z-index: 2;
				}

				.slider-text3 {
					animation-name: slider-anim-3;
					z-index: 3;
				}
				@keyframes slider-anim-1 {
					0%, 8.3% { opacity: 0; visibility: hidden; display: none; }
					8.3%,25% { opacity: 1; visibility: visible; display: inline; }
					33.33%, 100% { opacity: 0; visibility: hidden; display: none; }
				}

				@keyframes slider-anim-2 {
					0%, 33.33% { opacity: 0; visibility: hidden; display: none; }
					41.63%, 58.29% { opacity: 1; visibility: visible; display: inline; }
					66.66%, 100% { opacity: 0; visibility: hidden; display: none; }
				}

				@keyframes slider-anim-3 {
					0%, 66.66% { opacity: 0; visibility: hidden; display: none; }
					74.96%, 91.62% { opacity: 1; visibility: visible; display: inline; }
					100% { opacity: 0; visibility: hidden; display: none; }
				}
			}
		}
		.btn-line {
			display: flex;
			align-items: center;
			margin: 1em -1em;
			&:before,
			&:after {
				content: "";
				flex: 1;
				height: 1px;
				margin: 0 1em;
				background-color: $brandcolor2;
			}
		}
		p.lead {
			margin: 0 2.5em;
			font-family: adobe-garamond-pro, Didot, Georgia, 'Times New Roman', 'American Typewriter', serif;
			font-size: 1.75em;
			line-height: 1.25;
			@media (max-width: 992px) {
				margin: 0 1.6em;
			}
			@media (max-width: 768px) {
				margin: 0 0.8em;
			}
			@media (max-width: 576px) {
				margin: 0 0;
			}
		}
	}

	#slogan-callouts {
		z-index: 1;
		.card {
			padding: 0 0.625em;
		}
		.col-12:not(:last-child) {
			@media (min-width: 992px) {
				border-right: 1px solid $gray-white2;
			}
			@media (max-width: 992px) {
				border-bottom: none;
			}
		}

		.col-12 + .col-12 {
			@media (min-width: 992px) {
				margin-left: -1px;
			}
			@media (max-width: 992px) {
				margin-top: 0;
			}
		}
		img {
			margin-bottom: 0.625em;
		}
		h3 {
			color: $brandcolor1-dark;
			font-weight: 400;
			padding-bottom: 0.3125em;
			margin-bottom: 0.3125em;
			border-bottom: 1px solid $brandcolor2;
		}
		p {
			font-size: 0.9375em;
			line-height: 1.5em;
			color: $gray-dark1;
			b, strong {
				font-weight: 600;
				color: $gray-dark1;
			}
		}
	}

	#our-professionals {
		hr {
			width: 6.25em;
		}
		h2 {
			color: $brandcolor1-dark;
		}
		p.lead {
			margin: 0 2.5em;
			font-family: adobe-garamond-pro, Didot, Georgia, 'Times New Roman', 'American Typewriter', serif;
			font-size: 1.75em;
			line-height: 1.25;
			@media (max-width: 992px) {
				margin: 0 1.6em;
			}
			@media (max-width: 768px) {
				margin: 0 0.8em;
			}
			@media (max-width: 576px) {
				margin: 0 0;
			}
		}
		.btn {
			margin-top: 1.25em;
		}
	}

	#staff-photos {
			background: -moz-linear-gradient(180deg, rgba($gray-dark3,0) 0%, rgba($gray-dark3,0.5) 100%);
			background: -webkit-linear-gradient(180deg, rgba($gray-dark3,0) 0%, rgba($gray-dark3,0.5) 100%);
			background: linear-gradient(180deg, rgba($gray-dark3,0) 0%, rgba($gray-dark3,0.5) 100%);
			filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="$gray-dark3",endColorstr="$gray-dark3",GradientType=1);
		.block-overlay-full {
			background: -moz-linear-gradient(180deg, rgba($gray-dark3,0) 70%, rgba($gray-dark3,0.85) 100%);
			background: -webkit-linear-gradient(180deg, rgba($gray-dark3,0) 70%, rgba($gray-dark3,0.85) 100%);
			background: linear-gradient(180deg, rgba($gray-dark3,0) 70%, rgba($gray-dark3,0.85) 100%);
			filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="$gray-dark3",endColorstr="$gray-dark3",GradientType=1);
			// background-blend-mode: multiply;
			// mix-blend-mode: darken;
			z-index: 0;
		}
	}

	#our-practice {
		hr {
			width: 6.25em;
			margin: 0.625em 0 1.25em 0;
		}
		h2 {
			color: $brandcolor1-dark;
		}
		p.lead {
			font-family: adobe-garamond-pro, Didot, Georgia, 'Times New Roman', 'American Typewriter', serif;
			font-size: 1.75em;
			line-height: 1.25;
		}
	}

	#practice-callouts {
		h3 {
			font-size: 2.25em;
			color: $gray-dark1;
		}
	}

	#careers {
		.block-overlay-full {
			background: -moz-linear-gradient(180deg, rgba($white,0) 75%, rgba($white,1) 100%);
			background: -webkit-linear-gradient(180deg, rgba($white,0) 75%, rgba($white,1) 100%);
			background: linear-gradient(180deg, rgba($white,0) 75%, rgba($white,1) 100%);
			filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="$white",endColorstr="$white",GradientType=1);
			z-index: -1;
		}
		hr {
			width: 6.25em;
		}
		h2 {
			color: $brandcolor1-dark;
		}
		p.lead {
			font-family: adobe-garamond-pro, Didot, Georgia, 'Times New Roman', 'American Typewriter', serif;
			font-size: 1.5em;
			line-height: 1.25;
			margin-bottom: 1.25em;
		}
		p {
			line-height: 1.25;
		}
	}

	#latest-news {
		h2 {
			color: $brandcolor1-dark;
		}
	}

	#best-nwest {
		.text-one-column-container {
			.row {
				display: flex;
				justify-content: center;
				align-items: center;
				.block-text-image-body-column {
					padding: 0 0 0 2.5rem !important;
					@media (max-width: 767.9px) {
						padding: 2.5rem 0 0 0 !important;
					}
					hr {
						width: 6.25em;
						margin: 0.625em 0 1.25em 0;
					}
					h2 {
						color: $brandcolor1-dark;
					}
					p.lead {
						font-family: adobe-garamond-pro, Didot, Georgia, 'Times New Roman', 'American Typewriter', serif;
						font-size: 1.75em;
						line-height: 1.25;
					}
				}
			}
		}
	}

}
