/* General Form Inputs */
.custom-checkbox
.custom-control-input:checked~.custom-control-label::after {
		background-color: $brandcolor1;
}
.form-control:focus {
	border-color: $brandcolor1;
	box-shadow: inset 0 1px 1px rgba($brandcolor1-light, 0.075), 0 0 0.5em rgba($brandcolor1-light, 0.6);
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
input[type=number] {
	-moz-appearance: textfield;
}

button.active.focus, button.active:focus, button.focus, button.focus:active, button:active:focus, button:focus {
	outline: 0 !important;
	outline-offset: 0  !important;
	background-image: none  !important;
	-webkit-box-shadow: 0 0 0.5em rgba(0, 0, 0, 0.25) !important;
	box-shadow: 0 0 0.5em rgba(0, 0, 0, 0.25)  !important;
}

input {
	// width: 100%;
	padding: 0.625em;
	border: 1px solid $gray-light3;
	border-radius: 0 !important;
	box-shadow:inset 0 3px 0 $gray-white1;
	-moz-box-shadow:inset 0 3px 0 $gray-white1;
	-webkit-box-shadow:inset 0 3px 0 $gray-white1;
}

textarea {
	width: 100%;
	padding: 0.625em;
	border: 1px solid $gray-light3;
	border-radius: 0 !important;
	box-shadow:inset 0 3px 0 $gray-white1;
	-moz-box-shadow:inset 0 3px 0 $gray-white1;
	-webkit-box-shadow:inset 0 3px 0 $gray-white1;
}

.form-label-required {
	color: $brandcolor1-dark;
}

.form-check-input:not(:checked), .form-check-input:checked {
	// position: absolute;
	// pointer-events: none;
	// opacity: 0;
}

.btn-group-toggle {
	margin: 0 1.25em;
}

.btn-outline-group {
	-webkit-transition: transform 0.5s ease-out;
	-moz-transition: transform 0.5s ease-out;
	-ms-transition: transform 0.5s ease-out;
	-o-transition: transform 0.5s ease-out;
	transition: .5s ease-out;
	font-weight: 400 !important;
	text-transform: none !important;
	letter-spacing: 0 !important;
	background-color: none !important;
	color: $gray-dark2 !important;
	border: 1px solid $gray-light3 !important;
	&:hover {
		-webkit-transition: transform 0.5s ease-out;
		-moz-transition: transform 0.5s ease-out;
		-ms-transition: transform 0.5s ease-out;
		-o-transition: transform 0.5s ease-out;
		transition: .5s ease-out;
		background-color: $brandcolor1 !important;
		border: 1px solid $gray-light3 !important;
	}
	&:active, &:focus, &:not(:disabled):not(.disabled):focus {
		-webkit-transition: transform 0.5s ease-out;
		-moz-transition: transform 0.5s ease-out;
		-ms-transition: transform 0.5s ease-out;
		-o-transition: transform 0.5s ease-out;
		transition: .5s ease-out;
		background-color: $brandcolor1-light !important;
		border: 1px solid $gray-light3 !important;
		box-shadow: 0 !important;
		outline: none !important;
	}
}

.btn-outline-group:not(:disabled):not(.disabled):active, 
.btn-outline-group:not(:disabled):not(.disabled).active, 
.show>.btn-outline-group.dropdown-toggle {
	color: $white !important;
	background-color: $gray-dark1 !important;
 }

label {
	color: $gray-mid2;
	font-size: 0.875em;
	text-transform: uppercase;
	font-weight: 600;
	letter-spacing: 0.05em;
}

label.required:after  {
 color: $brandcolor1;
}
