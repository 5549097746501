/* People Main Page */
.people-practice-filter-container {
	padding: 2.5em 2.5em 0 2.5em;
	.navbar {
		margin: 0;
		padding: 0;
		border-top: 1px solid $brandcolor2;
		.people-practice-filter-label {
			margin: 0 !important;
			padding: 0 0.5625em !important;
			white-space: nowrap;
			font-family: adobe-garamond-pro, Didot, Georgia, 'Times New Roman', 'American Typewriter', serif;
			font-size: 1.25em;
			line-height: 0.8;
			color: $gray-mid2;
		}
		.navbar-nav {
			margin: 0 !important;
			padding: 0 !important;
			width: 100%;
			li {
				margin: 0 !important;
				padding: 0 !important;
				&::marker {
					content: "" !important;
					display: none !important;
				}
				.nav-link {
					color: $brandcolor1;
					margin: 0 !important;
					padding: 0.625em 0.625em !important;
					&:hover {
						color: $brandcolor1-dark;
						border-radius: 0;
						margin: 0 !important;
						padding: 0.625em !important;
					}
				}
				.active {
					background: linear-gradient(180deg, rgba($gray-white2,1) 0%, rgba($gray-white2,0) 100%);
					border-radius: 0 !important;
					border-left: 1px solid $brandcolor2;
					border-right: 1px solid $brandcolor2;
					@media (max-width: 992px) {
						background: linear-gradient(90deg, rgba($gray-white2,1) 0%, rgba($gray-white2,0) 100%);
						border-right: 1px solid $white;
					}
				}
			}
		}
		.navbar-toggler {
			padding: 0.875em 0.875em;
			font-family: proxima-nova-condensed, Roboto, Helvetica, Calibri, 'Segoe UI', sans-serif;
			font-size: 0.875em;
			line-height: 1;
			font-weight: 700;
			letter-spacing: .25em;
			text-transform: uppercase;
			color: $brandcolor1;
		}
		.navbar-toggler-icon {
			background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(50,71,122,1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
		}
	}
}

@media (max-width: 991.9px) {
	.people-practice-filter-container {
		.navbar {
			.people-practice-filter-label {
				flex-grow: 1;
				padding: 0.875em 0 !important;
				cursor: pointer;
			}
		}
	}
}

/* People Cards */
.people-card-container {
	padding: 1.0em 0 5.0em 0;
}
.people-card {
	margin: 0.625em;
	border-radius: 0;
	width: 100%;
	&:hover {
		& > * {
			cursor: pointer;
		}
		.card-img-overlay-hover {
			opacity: 1;
			pointer-events: initial;
		}
		.card-img {
			.card-img-back {
				opacity: 0.5;
				transform: scale(1.25);
				filter: blur(4px);
				-webkit-filter: blur(4px);
				-moz-filter: blur(4px);
				-ms-bfilter: blur(4px);
				-o-filter: blur(4px);
			}
		}
	}
	.card-img {
		top: initial;
		border-radius: 0;
		z-index: 0;
		overflow: hidden;
		.card-img-back {
			opacity: 0.75;
			filter: blur(0px);
			-webkit-filter: blur(0px);
			-moz-filter: blur(0px);
			-ms-bfilter: blur(0px);
			-o-filter: blur(0px);
			-webkit-transition: all 3.0s ease-out;
			-moz-transition: all 3.0s ease-out;
			-ms-transition: all 3.0s ease-out;
			-o-transition: all 3.0s ease-out;
			transition: all 3.0s ease-out;
		}
		.card-back-overlay {
			position: absolute;
			left: 0;
			bottom: 0;
			width: 100%;
			height: 100%;
			overflow: hidden;
			z-index: 2;
			pointer-events: none;
		}
	}
	.card-img-overlay {
		border-radius: 0;
		pointer-events: none;
		z-index: 3;
		.card-people-title {
			font-size: 1.625em;
			font-weight: 400;
			color: $brandcolor1;
			margin: 0;
		}
		.card-people-title-position {
			font-size: 0.875em;
		}
	}
	.card-img-overlay-hover {
		opacity: 0;
		top: initial;
		display: flex;
		justify-content: center;
		align-items: center;
		padding-top: 3.0em;
		background: -moz-linear-gradient(180deg, rgba($white,0) 0%, rgba($white,0.8) 100%);
		background: -webkit-linear-gradient(180deg, rgba($white,0) 0%, rgba($white,0.8) 100%);
		background: linear-gradient(180deg, rgba($white,0) 0%, rgba($white,0.8) 100%);
		background: linear-gradient(180deg, rgba($white,0) 0%, rgba($white,0.8) 100%);
		-webkit-transition: opacity 1.0s ease-out;
		-moz-transition: opacity 1.0s ease-out;
		-ms-transition: opacity 1.0s ease-out;
		-o-transition: opacity 1.0s ease-out;
		transition: opacity 1.0s ease-out;
		.buttons {
			position: static;
			z-index: 3;
			.btn {
				padding: 1.0em;
			}
		}
	}
}

/* Practice Area Cards */
.practice-attorney-card-container {
	margin: 0;
	padding: 0.5625em 0;
}
.practice-people-card {
	margin: 0.5626em 0;
	padding: 0;
	border-radius: 0;
	display: flex;
	align-items: center;
	.card-img-top {
		width: 50%;
		height: auto;
		top: initial;
		border-radius: 0;
		.card-img-back {
			opacity: 0.75;
		}
		.card-back-overlay {
			position: absolute;
			left: 25%;
			top: 0;
			width: 50%;
			height: 50%;
			// overflow: hidden;
			z-index: 0;
		}
	}
	.card-body {
		border-radius: 0;
		padding: 0.5625em;
		.card-people-title {
			font-size: 1.625em;
			font-weight: 400;
			color: $brandcolor1;
			margin: 0;
		}
		.card-people-title-position {
			font-size: 0.875em;
		}
	}
}

//Articles People Thumbnail
.news-people-card {
	margin: 0.625em 1.875em 0.625em 0;
	padding: 0;
	border-radius: none !important;
	display: flex;
	align-items: center;
	max-width: 240px;
	float: left;
	@media (max-width: 768px) {
		margin: 0.625em auto;
		float: none;
	}
	.card-img-top {
		width: 100%;
		height: auto;
		top: initial;
		border-bottom: 6px solid $brandcolor2;
		border-radius: 0 !important;
		.card-img-back {
			opacity: 0.75;
			border-radius: 0 !important;
		}
		.card-back-overlay {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			// overflow: hidden;
			z-index: 0;
			border-radius: 0;
			border-radius: 0 !important;
		}
	}
	.card-body {
		border-radius: 0 !important;
		padding: 0.5625em;
		.card-people-title {
			font-family: proxima-nova-condensed, Roboto, Helvetica, Calibri, 'Segoe UI', sans-serif;
			font-size: 1.25em;
			font-weight: 600;
			color: $gray-dark1;
			margin: 0;
			text-transform: initial;
		}
		.card-people-title-position {
			font-size: 0.875em;
		}
	}
}

//Main People Detail Page
.people-header-container {
	position: relative;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	.people-header-container-overlay-1 {
		opacity: 0.25;
		background: linear-gradient(180deg, rgba($gray-dark1,0.8) 0%, rgba($gray-dark1,0.25) 33%, rgba($gray-dark1,0.25) 66%, rgba($gray-dark1,0.8) 100%);
		position: absolute;
		left: 0;
		top: 0;
		bottom: 0;
		right: 0;
		width: 100%;
		height: 100%;
		z-index: 1;
	}
	.people-header-container-overlay-2 {
		opacity: 0.5;
		background: radial-gradient(circle, rgba($gray-dark1,0.25) 0%, rgba($gray-dark1,0.6) 100%);
		position: absolute;
		left: 0;
		top: 0;
		bottom: 0;
		right: 0;
		width: 100%;
		height: 100%;
		z-index: 2;
	}
	.people-header-text-overlay {
		position: relative;
		z-index: 3;
	}
}
.people-header-info-container, .people-body-info-container {
	color: $white;
	a {
		color: $white;
	}
	.people-header-divider {
		width: 6.0em;
		border-top: 1px solid $brandcolor2;
		color: $brandcolor2;
		margin-left: 0;
	}
	p {
		font-family: adobe-garamond-pro, Didot, Georgia, 'Times New Roman', 'American Typewriter', serif;
		font-size: 1.5em;
		color: $gray-white1;
		margin-bottom: 0.625em;
		line-height: 1;
		a {
			font-family: proxima-nova, Roboto, Helvetica, Calibri, 'Segoe UI', sans-serif;
			color: $white;
		}
	}
	.btn-sm {
		font-size: 1.0em;
		padding: 0.625em 1.25em;
	}
}
.people-body-info-container {
	padding: 2.5em 0;
	background-color: $gray-mid3;
}

.people-container {
	padding: 5.0em 0;
	ul {
		list-style-type: none;
		margin: 0 0 2.5em 0;
		padding: 0;
		li {
			margin: 0;
			padding: 0.625em 0;
			line-height: 1;
			border-bottom: 1px solid $gray-light1;
			&:first-child {
				padding-top: 0;
			}
			&:last-child {
				border-bottom: none;
			}
			&::marker {
				content: "";
				display: none;
			}
			b {
				color: $black;
				font-weight: 600;
			}
			&::before {
				content: "";
				display: none;
			}
		}
	}
	h3 {
		ul, li {
			position: relative;
			padding: 0;
			margin: 0;
			ul {
				margin-left: 1.5em;
				&::before {
					font-family: "Font Awesome 5 Free";
					content: "\f105";
					position: absolute;
					left: -0.75em;
					top: 0;
				}
			}
		}
	}
	.people-col1-container {
		@media (max-width: 768px) {
			padding-bottom: 5em;
		}
	}
}

.people-support-staff-container {
	.row {
		margin: 0;
		padding: 0.625em 0;
		border-bottom: 1px solid $gray-light1;
		&:first-child {
			// padding-top: 0;
		}
		&:last-child {
			border-bottom: none;
		}
	}
}

.people-related-news-container {
	position: relative;
	.row {
		margin: 0;
		padding: 0.625em 0;
		border-bottom: 1px solid $gray-light1;
		&:first-child {
			// padding-top: 0;
		}
		&:last-child {
			border-bottom: none;
		}
	}
}
