/* News Article Teaser Cards */
.card-news-container {
	margin: 0;
	padding: 0 2.5em 5.0em 2.5em;
	@media (max-width: 768px) {
		padding: 0 2.0em 5.0em 2.0em;
	}
}

.card-news-row {
	.col-12:not(:last-child) {
		@media (min-width: 992px) {
			border-right: 1px solid $gray-white2;
		}
		@media (max-width: 992px) {
			border-bottom: 1px solid $gray-white2;
		}
	}

	.col-12 + .col-12 {
		@media (min-width: 992px) {
			margin-left: -1px;
		}
		@media (max-width: 992px) {
			margin-top: -1px;
		}
	}
}

.card-news-row-lg {
	margin: 0;
	.col-12:first-child {
		padding: 0.9375em 0 1.125em 0;
	}
	.col-12 {
		padding: 1.125em 0;
	}
	.col-12:not(:last-child) {
		border-bottom: 1px solid $gray-white2;
	}
	.col-12 + .col-12 {
		margin-top: -1px;
	}
}

.card-news {
	margin: 1.25em 0;
	@media (max-width: 992px) {
		margin: 2.5em 0;
	}
	.card-news-header {
		h5 {
			color: $brandcolor1-dark;
		}
	}
	.card-news-body {
		font-size: 0.9em;
		line-height: 1.25;
	}
}

/* Main Articles Page */
.articles-categories-container {
	padding: 2.0em 2.5em 2.0em 1.0em !important;
	.category-list-title {
		padding-bottom: 0.25em;
		border-bottom: 1px solid $brandcolor2;
	}
	ul {
		li {
			font-family: proxima-nova-condensed, Roboto, Helvetica, Calibri, 'Segoe UI', sans-serif;
			font-size: 1.125em;
			a {
				font-weight: 600;
				&:hover {
					text-decoration: none;
				}
			}
		}
	}
}

/* News Detail Pages */
.news-body-container {
	padding: 0 2.5em 2.5em 2.5em;
	.news-container {
		padding: 1.875em 2.5em 0 2.5em;
	}
}

.news-subheadline-text {
	font-size: 1.5em;
	b {
		font-weight: 400;
		color: $gray-dark1;
	}
}

