.page-title-full-width {
	position: absolute;
	background-color: $gray-light1;
	left: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
	z-index: 0;
	-webkit-background-size: cover !important;
	-moz-background-size: cover !important;
	-o-background-size: cover !important;
	background-size: cover !important;
	background-repeat: no-repeat !important;
	background-position: center top;
	background-attachment: fixed;
	-webkit-filter: blur(20px) grayscale(25%);
	-moz-filter: blur(20px) grayscale(25%);
	-o-filter: blur(20px) grayscale(25%);
	filter: blur(20px) grayscale(25%);
	opacity: 0.75;
	overflow: hidden;
}

.page-title-back {
	background-color: $gray-dark2;
	position: relative;
	-webkit-background-size: cover !important;
	-moz-background-size: cover !important;
	-o-background-size: cover !important;
	background-size: cover !important;
	background-repeat: no-repeat !important;
	background-position: center top;
	background-attachment: fixed;
	max-width: 2200px;
	z-index: 2;
}

.page-title-overlay {
	opacity: 0.5;
	background: linear-gradient(180deg, rgba($white,0.8) 0%, rgba($white,0.2) 33%, rgba($white,0) 66%, rgba($white,0.8) 100%);
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
}

h1.page-title {
	display: block;
	margin: 0;
	padding: 0;
	width: 100%;
	border-bottom: 1px solid $brandcolor2;
}

.page-title-container {
	padding: 5.0em 2.5em 0.625em 2.5em;
}

.page-subtitle-container {
	padding: 0 1.5em 1.875em 1.5em;
}

.page-title-backlink {
	display: block;
	font-family: proxima-nova-condensed, Roboto, Helvetica, Calibri, 'Segoe UI', sans-serif;
	font-size: 0.75em;
	text-transform: uppercase;
	letter-spacing: 0.125em;
	color: $gray-mid2;
	font-weight: 600;
	margin: 0 0 1.875rem 0;
	a {
		color: $gray-mid2;
	}
	.fa {
		color: $brandcolor2;
	}
	&:hover {
		text-decoration: none;
	}
	&:active, &:focus, &:not(:disabled):not(.disabled):focus {
		text-decoration: none;
	}
}
