// Blocks General
.block-headline-text {
	margin: 0;
	line-height: 1;
}

.block-subheadline-text, .block-subheadline-text p {
	margin: 0.3125em 0 0 0;
	text-transform: none;
	line-height: 1;
}

.background-image-overlay {
	position: absolute;
	z-index: -10;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	width: 100%;
	height: 100%;
	pointer-events: none;
	// -webkit-background-size: cover;
	// -moz-background-size: cover;
	// -o-background-size: cover;
	// background-size: cover;
	// background-repeat: no-repeat;
	// background-position: center;
	@media (max-width: 768px) {
		background-attachment: scroll !important;
	}
}

.block-container-full, .block-container-content {
	position: relative;
}

.block-overlay-full, .block-overlay-content {
	position: absolute;
	z-index: -1;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	width: 100%;
	height: 100%;
	pointer-events: none;
}



// Images
.img-regular-padding {
	padding: 0 1.25em;
	@media (max-width: 992px) {
		padding: 2.5em 1.25em;
	}
}

.img-no-padding {
	padding: 0;
	@media (max-width: 992px) {
		padding: 2.5em 0;
	}
}

.img-square-crop, .img-circle-crop {
	position:relative;
}
.img-square-crop::after, .img-circle-crop::after {
	content: "";
	display: block;
	padding-bottom: 100%;
}
.img-square-crop img, .img-circle-crop img {
	position: absolute;
	width: 100%;
	height: 100%;
	object-fit: cover;
	object-repeat: no-repeat;
	@media (max-width: 992px) {
		padding: 2.5em;
	}
}

.img-circle-crop img {
	border-radius: 50%;
}

.img-overlay-sections {
	position: absolute;
	right: 2.5em;
	top: 0;
	@media (max-width: 992px) {
		position: static;
		padding: 2.5em 1.25em;
	}
}




// Large Text Callout
.large-text-callout-container {
	h2.large-text-callout-headline-text {
		font-family: proxima-nova-condensed, Roboto, Helvetica, Calibri, 'Segoe UI', sans-serif;
		font-weight: 400;
		font-size: 2em;
		color: $gray-dark2;
	}
	h3.large-text-callout-headline-text {
		font-size: 1.75em;
	}
	h4.large-text-callout-headline-text {
		font-size: 1.5em;
		text-transform: uppercase;
	}

	h4.large-text-callout-subheadline-text {
		font-family: adobe-garamond-pro, Didot, Georgia, 'Times New Roman', 'American Typewriter', serif;
		font-weight: 700;
		font-size: 1.5em;
		text-transform: uppercase;
	}
	h5.large-text-callout-subheadline-text {
		font-family: proxima-nova-condensed, Roboto, Helvetica, Calibri, 'Segoe UI', sans-serif;
		font-weight: 700;
		font-size: 1.25em;
		text-transform: uppercase;
	}
	h6.large-text-callout-subheadline-text {
		font-family: proxima-nova-condensed, Roboto, Helvetica, Calibri, 'Segoe UI', sans-serif;
		font-weight: 400;
		font-size: 1.0em;
		text-transform: uppercase;
	}
	.large-text-callout-body-copy {
		p {
			font-size: 2.5em;
			line-height: 1.2em;
		}
	}
}




//Pull Quote
.pull-quote-container { 
	h2.pull-quote-headline-text {
		font-family: proxima-nova-condensed, Roboto, Helvetica, Calibri, 'Segoe UI', sans-serif;
		font-weight: 400;
		font-size: 2em;
		color: $gray-dark2;
	}
	h3.pull-quote-headline-text {
		font-size: 1.75em;
	}
	h4.pull-quote-headline-text {
		font-size: 1.5em;
		text-transform: uppercase;
	}
	h5.pull-quote-headline-text {
		font-size: 1.25em;
		text-transform: uppercase;
	}
	h4.pull-quote-subheadline-text {
		font-family: adobe-garamond-pro, Didot, Georgia, 'Times New Roman', 'American Typewriter', serif;
		font-weight: 700;
		font-size: 1.5em;
		text-transform: uppercase;
	}
	h5.pull-quote-subheadline-text {
		font-family: proxima-nova-condensed, Roboto, Helvetica, Calibri, 'Segoe UI', sans-serif;
		font-weight: 700;
		font-size: 1.25em;
		text-transform: uppercase;
	}
	h6.pull-quote-subheadline-text {
		font-family: proxima-nova-condensed, Roboto, Helvetica, Calibri, 'Segoe UI', sans-serif;
		font-weight: 400;
		font-size: 1.0em;
		text-transform: uppercase;
	}
	.pull-quote-body-copy {
		p {
			font-size: 2.0em;
			line-height: 1.25em;
		}
	}
	cite.pull-quote-citation {
		display: block;
		color: $gray-dark3;
		font-size: 1.5em;
		font-style: normal;
		font-weight: 700;
		line-height: 1em;
		margin: 0.625em 0 1.25em 0;
		padding: 0;
	}
}






// Text-With-Image Block
.block-text-image-body-column {
	padding: 0 0.9375em !important;
}



// Accordion List Block
.accordion-block {
	.card {
		margin: 0;
		.card-header {
			margin: 0;
			padding: 0.9375em 0.5625em;
			border-bottom: 1px solid $gray-light2;
			.btn {
				margin: 0;
				padding: 0;
				font-family: adobe-garamond-pro, Didot, Georgia, 'Times New Roman', 'American Typewriter', serif;
				font-weight: 400;
				font-size: 2em;
				line-height: 1;
				color: $brandcolor1;
				text-transform: none;
				letter-spacing: 0;
				.active.focus, .active:focus, .focus, .focus:active, &:active:focus, &:focus {
					outline: 0 !important;
					outline-offset: 0 !important;
					background-image: none !important;
					-webkit-box-shadow: none !important;
					-moz-box-shadow: none !important;
					box-shadow: none !important;
				}
				&[aria-expanded="true"] {
					.fac { transform: rotate(180deg); }
				}
				.fac {
					display: inline-block;
					position: absolute;
					top: 0.5625em;
					right: 0.5625em;
					transition: transform 300ms ease-in-out;
					transform: rotate(0deg);
					color: $brandcolor2;
				}
			}
		}
		.card-body {
			font-size: 1.0rem;
			padding: 1.25em 0.9375em 1.25em 0.9375em;
			p { font-size: 1.0rem; }
			h3 { 
				font-size: 1.875rem;
				color: $gray-dark1;
			}
		}
	}
}


//Stat Facts Row
.stat-fact-number {
	display: inline;
	margin: 0;
	color: $gray-dark1;
	font-size: 5em;
	font-weight: 300;
	line-height: 1;
}
.stat-fact-prefix {
	display: inline;
	margin: 0;
	color: $gray-dark1;
	font-size: 3em;
	font-weight: 300;
	line-height: 1;
}
.stat-fact-suffix {
	display: inline;
	margin: 0;
	color: $gray-dark1;
	font-size: 3em;
	font-weight: 300;
	line-height: 1;
}
.stat-fact-desc {
	margin: 0;
	font-size: 1.2em;
	line-height: 1;
}
.stat-fact-citation {
	margin: 0;
	padding-top: 0.625em;
	font-size: 0.9em;
	font-weight: 300;
	line-height: 1;
	color: $gray-mid3;
}




//Image Callout Cards
.image-callout-card {
	border: none;
	border-radius: 0;
}
.image-callout-headline {
}
.image-callout-body p {
	font-size: 1.0em;
	line-height: 1.2em;
}



//Image Icon Row
.image-icon-container {
	padding: 1.25em;
}
.image-icon-link {
	&:hover {
		text-decoration: none;
	}
}
.image-icon-headline {
	color: $gray-mid3;
	margin-top: 1.25em;
}

.image-icon-text p {
	color: $gray-dark2;
	line-height: 1.2em;
	margin-top: 1.25em;
}
.image-icon-image {
	display: block;
	margin-left: auto;
	margin-right: auto;
}


//Video Block
.embed-container {
	position: relative;
	padding-bottom: 56.25%;
	height: 0; overflow:
	hidden;
	max-width: 100%;
}
.embed-container iframe,
.embed-container object,
.embed-container embed {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

//People Slider Block
.people-slider {
	width: 100vw;
	z-index: 0;
}
.people-slider-wrapper {
	// -webkit-transition-timing-function: linear !important;
	// -moz-transition-timing-function: linear !important;
	// -ms-transition-timing-function: linear !important;
	// -o-transition-timing-function: linear !important;
	// transition-timing-function: linear !important;
	// -webkit-transition-duration: 3s !important;
	// -moz-transition-duration: 3s !important;
	// -ms-transition-duration: 3s !important;
	// -o-transition-duration: 3s !important;
	// transition-duration: 3s !important;
}
.people-slide {
	display: flex;
	justify-content: center;
	align-items: flex-end;
	overflow: visible;
}

.people-slide img {
	max-width: initial !important;
	display: block;
	// width: auto;
	// height: 100%;
	object-fit: cover;
	overflow: visible;
}
